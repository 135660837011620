import React from 'react';

// tslint:disable max-line-length
const CLetThemKnowPart3TranscriptCop26: React.FC = () => (
  <>
    <h5>Record a message for part 3</h5>
    <p>
      It’s time to record your final message. Someone gave me this advice
      recently, and while we’re all being so generous, I thought...why not pass
      it on. They said to me – don’t worry about explaining too much. That’s it.
      I didn’t say it was long. I just thought, you know, it could help... So,
      is there a thought you want to leave them with
    </p>
  </>
);

export {
  CLetThemKnowPart3TranscriptCop26,
};
