import React from 'react';

// tslint:disable max-line-length
const ROutroLocalPersonalTranscriptCop26: React.FC = () => (
  <>
    <h5>Postscript</h5>
    <p>
      Hey. Well here we are – on the other side. I figured I’d leave you to it.
      I wonder...did the gift surprise you, or do you know each other so well
      that surprises are kind of impossible... Maybe you’ll return the favour?
    </p>
  </>
);

export {
  ROutroLocalPersonalTranscriptCop26,
};
