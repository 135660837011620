import React from 'react';

// tslint:disable max-line-length
const CStartGiftTranscriptCop26: React.FC = () => (
  <>
    <h5>Introduction</h5>
    <p>
      Hey. Thanks for taking the time to open Gift. This was created by Blast
      Theory and the University of Nottingham. You may have just two minutes to
      spare. You may have all day. There is a whirlwind happening around you and
      this is a moment to think about someone you love on this planet. Today,
      you’re going make a gift for that person. They might be next to you right
      now. They might be on the other side of the world. Close your eyes and try
      to get a picture of them in your head.
    </p>
  </>
);

export {
  CStartGiftTranscriptCop26,
};
