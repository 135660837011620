import React from 'react';

// tslint:disable max-line-length
const RIntroContentRemotePersonalTranscriptionCop26: React.FC = () => (
  <>
    <h5>Introduction</h5>
    <p>
      Hey. So you’re the one getting the gift. Lucky. Um...let me explain.
      Someone has searched out things they thought you would care about. You
      were the lens for everything they looked at. And they took photos and made
      messages. This is kind of between you two, so I’m not going to say much
      more. I’ll see you on the other side.
    </p>
  </>
);

export {
  RIntroContentRemotePersonalTranscriptionCop26,
};
