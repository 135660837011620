import React from 'react';

// tslint:disable max-line-length
const CShareTranscriptCop26: React.FC = () => (
  <>
    <h5>Postscript</h5>
    <p>
      Hey. So, there are people who believe that the more you give away, the
      richer you are. Are you one of them? I don’t think it just has to be about
      ridding yourself of all your material wealth – like a monk – I think it
      can just be about working out what you have to give and whether you’re
      willing to give it. You’ve given these things without taking them. You’ve
      taken time, and I know someone is going to appreciate that. Yeh. I think
      they’re gonna like it.
    </p>
  </>
);

export {
  CShareTranscriptCop26,
};
