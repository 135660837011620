import React from 'react';

// tslint:disable max-line-length
const CLetThemKnowPart2TranscriptCop26: React.FC = () => (
  <>
    <h5>Record a message for part 2</h5>
    <p>
      Time for your second message, to go along with your photo. I’m wondering
      how far you’ve walked, and whether those steps have taken you across
      cultures, continents, ecosystems? When you’re ready, hit record.
    </p>
  </>
);

export {
  CLetThemKnowPart2TranscriptCop26,
};
