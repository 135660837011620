import React from 'react';

/**
 * Help content included in the menu/header
 */

// tslint:disable max-line-length
export const HelpContent: React.FC = () => (
  <>
    <h1>Help</h1>
    <h5>What is Gift?</h5>
    <p>Gift invites you to pick someone you love and create the perfect digital gift for them on your phone. Choose from the things you discover at COP26, take a photo and record a personal message for each one, then send it.</p>
    <h5>Who can make a gift?</h5>
    <p>You can. Just open Gift on your smart phone.</p>
    <h5>Who can I make gifts for?</h5>
    <p>You can choose anyone. Someone you wish could be with you at COP26. Or someone who’s standing next to you now. It’s up to you.</p>
    <hr/>
    <h2>About phones and devices</h2>
    <h5>What devices can I use?</h5>
    <p>Gift is designed to run on most modern smart phones. It has been tested on devices running Android 6.0 or newer and in Firefox or Safari on iOS11 or newer.</p>
    <p>You can also open gifts (but not create gifts) on a laptop or desktop computer.</p>
    <h5>What devices and browsers are not supported?</h5>
    <p>Chrome on iOS is not currently supported. Older budget Android devices may also have problems loading Gift.</p>
    <h5>Do I need to install an app?</h5>
    <p>No. Gift runs entirely in your phone’s web browser so there’s no need to download an app.</p>
    <h5>Do I need to use headphones?</h5>
    <p>No. Feel free to use your phone speaker to listen to Gift. Some wireless headphones are not supported.</p>
    <h5>Why can't I record messages?</h5>
    <p>Some headsets and bluetooth headphones are incompatible with voice recording from your phone browser. If you're using a headset or headphones, try disconnecting them to record your messages.</p>
    <p>If recording fails to start at all, check the microphone permissions for your browser in your phone's settings.</p>
    <h5>Do I need to use Wifi?</h5>
    <p>You will need an internet connection on your phone to use Gift. This can use either WiFi or mobile data.</p>
    <hr/>
    <h2>About making gifts</h2>
    <h5>Who can do this?</h5>
    <p>People aged as young as 8 and over 75 can create gifts. Those aged 10 or under may need assistance from someone they’re with.</p>
    <h5>Can I make a gift as a group or with someone?</h5>
    <p>Yes. You can work with other people to make a gift on a single phone.</p>
    <h5>Can I do this while wandering around doing other things?</h5>
    <p>Yes, it is possible and works well like this.</p>
    <h5>What’s involved in making a gift?</h5>
    <p>Explore an exhibition with a person you love or who is special in mind. An audio narrator guides you in choosing up to three things. They must take a photo of each object, record an audio message about why you chose it and how to find it.</p>
    <h5>Who can I make gifts for?</h5>
    <p>You can choose anyone. Someone you wish could be with you at the museum today. Or someone who’s right next to you now.</p>
    <h5>Do I have to choose three objects?</h5>
    <p>No. You can create gifts with one, two or three parts.</p>
    <h5>How long does it take to make a gift?</h5>
    <p>It’s up to you. It will take between 2 and 20 minutes.</p>
    <h5>How do I send my gifts?</h5>
    <p>To send gifts, you’ll need the email or mobile number of the person you’ve chosen or have them as a contact in WhatsApp or Facebook Messenger.</p>
    <h5>Can I open gifts I’ve made myself?</h5>
    <p>Yes. You can view gifts you’ve sent by opening the link in your sent messages.</p>
    <hr/>
    <h2>About receiving gifts</h2>
    <h5>Can someone without a smart phone open gifts?</h5>
    <p>Yes, recipients can open your gifts on a desktop computer, laptop or tablet.</p>
    <h5>I’ve lost the gift someone sent me. How can I find it?</h5>
    <p>If you’ve lost the link for your gift please ask the sender to resend it. Gifts are sent as public links.</p>
    <hr/>
    <h2>About privacy and data</h2>
    <h5>Are the gifts that I make private?</h5>
    <p>No, gifts are shared using public links and can be opened by anyone that the creators give the link to. Please note that these links can be passed on or shared publicly.</p>
    <h5>Who has my data? Is it shared with anyone?</h5>
    <p>Data for gifts is stored by Blast Theory. To read our terms of use & privacy policy choose ‘Privacy’ in the main menu.</p>
    <p>Blast Theory will never share your personal information with third parties without your consent. However, in order to share gifts, users give Blast Theory a license to publish contributions; including photos and audio messages on the user’s behalf. Under this license, these contributions may also be used - in an anonymised form - to document the project and to study how the website is used.</p>
    <p>Blast Theory also gathers anonymised usage data from use of the website to help improve its service. </p>
    <h5>Why does Gift need access to my camera and microphone?</h5>
    <p>You take photos and record voice messages to create gifts. Your browser needs permission to access the camera and microphone on your device to do this.</p>
    <h5>How much data do gifts use?</h5>
    <p>Gifts are generally less than 10MB each.</p>
    <h5>How long do you store my gifts for? Will you delete them?</h5>
    <p>Gift is planned to run throughout COP26. Gifts will be accessible for a minimum of three months. Gifts may be deleted at the request of users (see below). Please read our terms and privacy for details of storing and deleting your data.</p>
    <h5>How can I report abuse or request to delete a gift or my data?</h5>
    <p>Please email Blast Theory at gift@blasttheory.co.uk or choose 'Feedback' from the main menu.</p>
    <hr/>
    <h2>Accessibility and more</h2>
    <h5>Does Gift have sound?</h5>
    <p>Yes. Gifts you receive include audio messages from the sender. The website also has a narrator that guides you through creating and opening gifts.</p>
    <h5>I have a hearing impairment. Can I still use Gift?</h5>
    <p>Gifts contain audio messages that you will need to listen to when you open your gift. When creating gifts you need to record audio messages to accompany each object using your phone. A transcript is available for the narrator’s audio throughout the experience. Tap on the captions icon above the audio player.</p>
    <h5>I have a vision impairment. Can I still use Gift?</h5>
    <p>Photos are used in the interface to identify the things included in gifts. These are shown alongside text clues to help locate them. When creating gifts, you are also asked to take photos of the things you would like to choose.</p>
    <h5>I have problems using Gift. How can I tell you about them?</h5>
    <p>Please let us know about any experiences or issues using Gift choose 'Feedback' from the main menu. We'll do our best to fix them.</p>
    <hr/>
    <h2>Who made the work and why?</h2>
    <h5>Who made Gift?</h5>
    <p>Gift is made by the artist group, Blast Theory as part of a three year research project funded by the European Union’s Horizon 2020 research and innovation programme under grant agreement No 727040. It is a collaboration with IT University Copenhagen, University of Nottingham, University of Uppsala, Blast Theory, Next Game, the Europeana Foundation and Culture24.</p>
    <p>Visit the project website for more information: <a href='https://gifting.digital' target='_blank'>gifting.digital</a></p>
    <h5>Who are Blast Theory?</h5>
    <p>Visit our website: <a href='https://www.blasttheory.co.uk/projects/gift' target='_blank'>www.blasttheory.co.uk</a></p>
    <h5>Why is Gift at COP26?</h5>
    <p>Gift at COP26 is supported by UKRI and the University of Nottingham. It invites you to explore the UKRI exhibition and COP26 through the eyes of someone special to you.</p>
    <h5>How do I feedback on my experience of Gift?</h5>
    <p>To let us know about your experience,  choose 'Feedback' from the main menu.</p>

    <hr/>
    <h2>Contacting us</h2>
    <p>
      If you have any questions or need further help please contact us at:
    </p>
    <p>
      Blast Theory
      <br />
      Unit 5, 20 Wellington Road
      <br />
      Portslade, Brighton
      <br />
      East Sussex, BN41 1DN
      <br />
      UK
    </p>
    <p>
      Email:
      <a href='mailto:gift@blasttheory.co.uk'>gift@blasttheory.co.uk</a>
    </p>
  </>
);
