import React from 'react';

// tslint:disable max-line-length
const CChoosePart3Cop26: React.FC = () => (
  <>
    <h5>Choosing part 3</h5>
    <p>
      Now it’s time to finish this off. Beginning: done. Middle: done. You need
      an ending. Does this person know you so well that they’ve already worked
      out what’s coming last? Push them a little bit. Right? You’ve come to a
      place that is spilling over with ideas and stories. It’s not too late to
      surprise them.
    </p>
  </>
);

export {
  CChoosePart3Cop26,
};
