import React from 'react';

// tslint:disable max-line-length
const CChoosePart1Cop26: React.FC = () => (
  <>
    <h5>Choosing part 1</h5>
    <p>
      You can tell a gift that somebody has thought about, compared to one where
      they panicked, or didn’t bother, or ran out of ideas, can’t you? A good
      one sort of tells you about what they mean to you. Let that person be your
      filter as you explore today. Is there something here that would surprise
      them? Is there something that will trigger a memory of a time you spent
      together? Or will you choose something that lets you both think about the
      future. It's between you and the person you've chosen.
    </p>
  </>
);

export {
  CChoosePart1Cop26,
};
