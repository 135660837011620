import React from 'react';

// tslint:disable max-line-length
const CChoosePart2Cop26: React.FC = () => (
  <>
    <h5>Choosing part 2</h5>
    <p>
      Hey. Nice one. If you know where you’re going next, then just start
      walking. If you don’t, wander with your eyes wide open. Ok? Part two – the
      difficult second album – that’s a challenge. So, where are you going to
      take them next?
    </p>
  </>
);

export {
  CChoosePart2Cop26,
};
