import React from 'react';

// tslint:disable max-line-length
const RIntroContentLocalPersonalTranscriptionCop26: React.FC = () => (
  <>
    <h5>Introduction</h5>
    <p>
      Hey. So you’re the one getting the gift. Lucky. I know someone has been
      thinking about you. I’ll explain. Ok? You were the lens for everything
      they looked at. They searched out things they thought you would care about
      and they took photos and made messages. I’ll see you on the other side.
    </p>
  </>
);

export {
  RIntroContentLocalPersonalTranscriptionCop26,
};
