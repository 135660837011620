import React from 'react';

// tslint:disable max-line-length
const CLetThemKnowPart1TranscriptCop26: React.FC = () => (
  <>
    <h5>Record a message for part 1</h5>
    <p>
      They say it’s the thought that counts. Sure, it’s a cliché but it’s true,
      right? Speak to this person as if you were leaving them a voicemail. Let
      them know why you chose this. Imagine them standing here with you – what
      do they need to hear? I can’t help you on this one – sorry – it’s better
      if it comes from you.Take your time to think, and tap the button when
      you’re ready.
    </p>
  </>
);

export {
  CLetThemKnowPart1TranscriptCop26,
};
